import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="d-flex flex-column flex-md-row into-nav main-container">
      <div className="column">
        <video
          className="column-video"
          width="100%"
          autoPlay
          loop
          muted
          preload="auto"
          src="/video.mp4"
        ></video>
        {/* <div className="logo-bg">
            <img src={"/green-q.svg"} />
          </div> */}
      </div>
      <div className="column">
        <video
          className="column-video"
          width="100%"
          autoPlay
          loop
          muted
          preload="auto"
          src="/video.mp4"
        ></video>
      </div>
      <div className="content-container d-flex align-items-center justify-content-md-center text-md-center">
        <h1 className="title-text">Connecting People With Similar Interest</h1>
        <div className="header-icon-container">
          <a
            className=""
            href="https://play.google.com/store/apps/details?id=com.qlume.app"
          >
            <img src={"/play-store.png"} alt="Play Store logo" />
          </a>
          <a
            className="ml-3"
            href="https://apps.apple.com/vn/app/qlume-social-media-connect/id1554809521"
          >
            <img src={"/apple-store.png"} alt="Apple Store logo" />
          </a>
        </div>
      </div>
    </div>
    <div className="swipe-container ">
      <div className="swipe-title-container">
        <h2 className="swipe-title">Swipe and connect. It's really</h2>
        <h2 className="swipe-bold-text"> that simple!</h2>
      </div>
      <div className="swipe-img-container d-none d-md-block">
        <img src={"/mobiles.png"} alt="" />
      </div>
      <div className="swipe-img-container d-md-none">
        <img src={"/mob.png"} alt="" />
      </div>
    </div>
    <div className="people-circle-container d-flex flex-column flex-md-row align-items-md-center">
      <div className="first-column">
        <img src={"/people-circle.png"} alt="" />
      </div>
      <div className="second-column">
        <h3 className="people-circle-title">Why QluMeIn?</h3>
        {/* <h3 className="people-circle-title">most important</h3> */}
        <p>
          QluMeIn asks you 21 fun and easy questions called Qlus. Your answers
          give QluMeIn the unique ability to quickly find you friends with
          similar interests. QluMeIn will help you connect and enjoy life -
          whether you enjoy playing a sport, going for a drink, taking a hike,
          or walking on the beach.
        </p>
        <p>
          Simply download the app, answer the Qlus, and start enjoying life with
          your new network of QluMeIn friends.
        </p>
        <div>
          <a
            className=""
            href="https://play.google.com/store/apps/details?id=com.qlume.app"
          >
            <img src={"/play-store.png"} alt="Play Store logo" />
          </a>
          <a
            className="ml-3"
            href="https://apps.apple.com/vn/app/qlume-social-media-connect/id1554809521"
          >
            <img src={"/apple-store.png"} alt="Apple Store logo" />
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
